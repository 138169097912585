<template>
  <div class="terms">
    <Header />
    <div class="terms-content">
      <div class="title">{{ $t('terms.title') }}</div>
      <div class="text date">{{ $t('terms.text_1_1') }}</div>
      <div class="text text_1" v-html="$t('terms.text_1_2')"></div>
      <div class="text" v-html="$t('terms.text_1_3')"></div>
      <div class="text">{{ $t('terms.text_1_4') }}</div>
      <div class="sub-title">{{ $t('terms.text_2_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_2_2') }}</div>
      <div class="sub-title">{{ $t('terms.text_3_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_3_2') }}</div>
      <div class="text">{{ $t('terms.text_3_3') }}</div>
      <div class="text">{{ $t('terms.text_3_4') }}</div>
      <div class="text">{{ $t('terms.text_3_5') }}</div>
      <div class="text" v-html="$t('terms.text_3_6')"></div>
      <div class="text">{{ $t('terms.text_3_7') }}</div>
      <div class="sub-title">{{ $t('terms.text_4_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_4_2') }}</div>
      <div class="text">{{ $t('terms.text_4_3') }}</div>
      <div class="text text_2">{{ $t('terms.text_4_4') }}</div>
      <div class="text text_2">{{ $t('terms.text_4_5') }}</div>
      <div class="text text_2">{{ $t('terms.text_4_6') }}</div>
      <div class="text text_2">{{ $t('terms.text_4_7') }}</div>
      <div class="text text_2">{{ $t('terms.text_4_8') }}</div>
      <div class="text">{{ $t('terms.text_4_9') }}</div>
      <div class="sub-title">{{ $t('terms.text_5_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_5_2') }}</div>
      <div class="text">{{ $t('terms.text_5_3') }}</div>
      <div class="text text_2">{{ $t('terms.text_5_4') }}</div>
      <div class="text text_2">{{ $t('terms.text_5_5') }}</div>
      <div class="text text_2">{{ $t('terms.text_5_6') }}</div>
      <div class="text text_2">{{ $t('terms.text_5_7') }}</div>
      <div class="text text_2">{{ $t('terms.text_5_8') }}</div>
      <div class="text text_2">{{ $t('terms.text_5_9') }}</div>
      <div class="text text_2">{{ $t('terms.text_5_10') }}</div>
      <div class="text text_2">{{ $t('terms.text_5_11') }}</div>
      <div class="text text_2">{{ $t('terms.text_5_12') }}</div>
      <div class="text text_2">{{ $t('terms.text_5_13') }}</div>
      <div class="sub-title">{{ $t('terms.text_6_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_6_2') }}</div>
      <div class="sub-title">{{ $t('terms.text_7_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_7_2') }}</div>
      <div class="sub-title">{{ $t('terms.text_8_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_8_2') }}</div>
      <div class="sub-title">{{ $t('terms.text_9_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_9_2') }}</div>
      <div class="sub-title">{{ $t('terms.text_10_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_10_2') }}</div>
      <div class="sub-title">{{ $t('terms.text_11_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_11_2') }}</div>
      <div class="sub-title">{{ $t('terms.text_12_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_12_2') }}</div>
      <div class="text text_3">{{ $t('terms.text_13_1') }}</div>
      <div class="text text_1">{{ $t('terms.text_13_2') }}</div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Layouts/Header.vue';
import Footer from '../components/Layouts/Footer.vue';
export default {
  components: { Header, Footer },
};
</script>

<style lang="less" scoped>
@import url('../style/terms.less');
</style>
