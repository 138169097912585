<template>
  <div class="stake-vote-container">
    <div class="vote-wrap pic-full">
      <img class="tpt-dao" src="../../assets/pc/stake/tptdao.png" alt="" />
      <p>
        {{ $t('stake.voteTip') }}
      </p>

      <button @click="goVote()" class="btn primary vote-btn">
        {{ $t('stake.goVote') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "stakeVote",
  props: {
    homeLang: {
      type: Object,
    },
  },
  methods: {
    goVote() {
      location.href = "https://snapshot.org/#/tptdao.eth/";
    },
  },
};
</script>

<style lang="less" >
@media screen and (min-width: 767px) {
  .vote-wrap {
    position: relative;
    font-size: 0.14rem;
    margin-top: 0.5rem;
    width: 10.28rem;
    height: 2.09rem;
    background-image: url("../../assets/pc/stake/banner-bg.png");
    //padding: 0.4rem;

    img {
      height: 0.41rem;
      margin-bottom: 0.12rem;
      margin-left: 0.5rem;
      margin-top: 0.51rem;
    }
    p {
      width: 3.3rem;
      margin-left: 0.48rem;
      color: #fff;
      font-size: 0.18rem;
      font-family: PingFangSC, PingFangSC-Regular;
      line-height: 0.26rem;
    }

    .vote-btn {
      position: absolute;
      width: 3.62rem;
      right: 0.32rem;
      top: 0.7rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .vote-wrap {
    position: relative;
    font-size: 0.14rem;
    width: 7.16rem;
    height: 1.43rem;
    margin: 0.37rem auto 0;
    background-image: url("../../assets/mobile/stake/banner-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    img {
      height: 0.29rem;
      margin-top: 0.35rem;
      margin-left: 0.34rem;
    }
    p {
      width: 5.38rem;
      opacity: 1;
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #ffffff;
      line-height: 0.44rem;
      margin: 0.07rem 0.36rem;
      transform: scale(0.5);
      transform-origin: 0 0;
    }

    .vote-btn {
      position: absolute;
      width: 2.55rem;
      height: 0.54rem;
      right: 0.21rem;
      top: 0.52rem;
      padding: 0;
    }
  }
}
</style>
