<template>
  <div class="fee-content">
    <div class="fee-content-top">
      {{ $t('fee.desc') }}
    </div>
    <!-- pc端费率折扣 -->
    <div class="fee-content-discount">
      <div class="title">{{ $t('fee.feeDiscount') }}</div>
      <div class="fee-discount-wrap">
        <img class="fee-content-bg" :src="feeContentBg" />
        <div class="fee-level-wrap">
          <div class="fee-level-top">
            <span>{{ $t('fee.levelRank') }}</span>
            <span>{{ $t('fee.levelBalance') }}</span>
            <span>{{ $t('fee.levelDiscountRate') }}</span>
          </div>
          <div
            class="fee-level-list"
            v-for="(item, index) in level"
            :key="index"
          >
            <div :class="{ whiteBg: index % 2 === 0 }"></div>
            <div class="rank">
              <img :src="item.icon" />
              <span>{{ item.rank }}</span>
            </div>
            <span class="balance">{{ item.balance }}</span>
            <span class="discount-rate">{{ item.discountRate }}</span>
          </div>
        </div>
      </div>
      <img class="xtpt" :src="xtpt" />
      <img class="tpt" :src="tpt" />
    </div>
    <!-- pc端费钱包余额 -->
    <!-- <div class="fee-content-wallet-balance">
      <div class="balance-title">
        <span>{{ homeLang.fee.walletBalance }}</span>
        <div></div>
        <span>{{ userName }}</span>
      </div>
      <div class="fee-content-wallet-balance-wrap">
        <img class="fee-banlance-bg" :src="feeBanlanceBg" />
        <div class="fee-wallet-banlance-box">
          <div
            class="box-item"
            v-for="(item, index) in walletBanlancelist"
            :key="index"
          >
            <div class="item-left">
              <span class="amount" :class="{ active: index === 2 }">{{
                item.amount
              }}</span>
              <div class="item-type" :class="{ active: index === 2 }">
                <img :src="item.icon" :class="{ active: index === 2 }" />
                <span :class="{ active: index === 2 }">{{ item.text }}</span>
              </div>
            </div>
            <div :class="{ line: index !== 2 }"></div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 移动端钱包余额 -->
    <!-- <div class="fee-content-wallet-balance-mini">
      <div class="balance-title">
        <span>{{ homeLang.fee.walletBalance }}</span>
        <div></div>
        <span>{{ userName }}</span>
      </div>
      <div class="fee-content-wallet-balance-wrap">
        <div class="fee-wallet-banlance-box">
          <div
            class="box-item"
            v-for="(item, index) in walletBanlancelisMini"
            :key="index"
          >
            <div class="item-left">
              <span class="amount">{{ item.amount }}</span>
              <div class="item-type">
                <img :src="item.icon" />
                <span>{{ item.text }}</span>
              </div>
            </div>
            <div :class="{ line: index !== 1 }"></div>
          </div>
        </div>
        <div class="fee-cenoent-banlance-total">
          <div class="total-btn">
            <img :src="walletBanlancelist[2].icon" />
            <span>{{ walletBanlancelist[2].text }}</span>
          </div>
          <span class="amount">{{ walletBanlancelist[2].amount }}</span>
        </div>
      </div>
    </div> -->
    <!-- 移动端费率折扣 -->
    <div class="fee-content-discount-mini">
      <div class="title">{{ $t('fee.feeDiscount') }}</div>
      <div class="fee-discount-wrap">
        <!-- <img class="fee-content-bg" :src="feeContentBg" /> -->
        <div class="fee-level-wrap">
          <div class="fee-level-top">
            <span>{{ $t('fee.levelRank') }}</span>
            <span>{{ $t('fee.levelBalance') }}</span>
            <span>{{ $t('fee.levelDiscountRate') }}</span>
          </div>
          <div
            class="fee-level-list"
            v-for="(item, index) in level"
            :key="index"
          >
            <div :class="{ whiteBg: index % 2 === 0 }"></div>
            <div class="rank">
              <img :src="item.icon" />
              <span>{{ item.rank }}</span>
            </div>
            <span class="balance">{{ item.balance }}</span>
            <span class="discount-rate">{{ item.discountRate }}</span>
          </div>
        </div>
      </div>
      <!-- <img class="xtpt" :src="xtpt" />
      <img class="tpt" :src="tpt" /> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      feeContentBg: require('../../assets/pc/fee/discount_bg.png'),
      feeBanlanceBg: require('../../assets/pc/fee/banlance_bg.png'),
      xtpt: require('../../assets/pc/fee/xtpt.png'),
      tpt: require('../../assets/pc/fee/tpt.png'),
      walletBanlancelist: [
        {
          amount: '10,000,000.1234',
          text: 'TPT',
          icon: require('../../assets/pc/fee/tpt_s.png'),
        },
        {
          amount: '999,999.1234',
          text: 'xTPT',
          icon: require('../../assets/pc/fee/xtpt_s.png'),
        },
        {
          amount: '10,999,999.2468',
          text: '总量',
          icon: require('../../assets/pc/fee/total.png'),
        },
      ],
      userName: 'adfklgjhsighritwhg',
    };
  },
  computed: {
    walletBanlancelisMini() {
      return this.walletBanlancelist.slice(0, 2);
    },
    level() {
      return [
        {
          rank: 'LV.1',
          icon: require('../../assets/pc/fee/lv1.png'),
          balance: '≥10,000',
          discountRate: '10%',
        },
        {
          rank: 'LV.2',
          icon: require('../../assets/pc/fee/lv2.png'),
          balance: '≥100,000',
          discountRate: '20%',
        },
        {
          rank: 'LV.3',
          icon: require('../../assets/pc/fee/lv3.png'),
          balance: '≥1,000,000',
          discountRate: '40%',
        },
        {
          rank: 'LV.4',
          icon: require('../../assets/pc/fee/lv4.png'),
          balance: '≥10,000,000',
          discountRate: '70%',
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
// 定义一个标题的混合
.common-title {
  // width: 7.16rem;
  height: 0.33rem;
  margin: 0 auto;
  font-size: 0.24rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 0.33rem;
}
// pc端css
@media screen and (min-width: 767px) {
  .title {
    width: 7.16rem;
    margin-left: 0.58rem;
    .common-title();
  }
  .fee-content {
    .fee-content-top {
      width: 7.16rem;
      margin: 0 auto;
      font-size: 0.2rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: justify;
      color: #333333;
    }
    .fee-content-discount {
      display: block;
      margin-top: 0.3rem;
      position: relative;
      .fee-discount-wrap {
        width: 8.33rem;
        height: 3.34rem;
        background: url('../../assets/pc/fee/discount_projection.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        .fee-content-bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.52rem;
          height: 3.24rem;
        }
        .fee-level-wrap {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.52rem;
          height: 3.24rem;
          .fee-level-top {
            width: 6.72rem;
            height: 0.58rem;
            display: flex;
            justify-content: space-evenly;
            margin: 0 auto;
            margin-top: 0.1rem;
            span {
              font-size: 0.2rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              text-align: center;
              color: #333333;
              line-height: 0.58rem;
            }
            span:nth-child(1) {
              width: 1.5rem;
            }
            span:nth-child(2) {
              flex: 1;
            }
            span:nth-child(3) {
              width: 2rem;
            }
          }
          .fee-level-list {
            position: relative;
            width: 6.72rem;
            height: 0.58rem;
            margin: 0 auto;
            display: flex;
            justify-content: space-evenly;
            span {
              font-size: 0.18rem;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: center;
              color: #333333;
              line-height: 0.58rem;
            }
            .balance {
              flex: 1;
            }
            .discount-rate {
              width: 2rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              color: #2761e7;
            }
            .rank {
              width: 1.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 0.44rem;
                height: 0.44rem;
                margin-right: 0.2rem;
              }
            }
          }
          .whiteBg {
            position: absolute;
            z-index: -10000;
            width: 6.72rem;
            height: 0.58rem;
            margin: 0 auto;
            opacity: 0.4;
            background: #ffffff;
          }
        }
      }
      .xtpt {
        position: absolute;
        top: -0.2rem;
        right: -0.15rem;
        width: 1.57rem;
        height: 1.49;
      }
      .tpt {
        position: absolute;
        bottom: 0;
        left: -0.36rem;
        width: 1.41rem;
        height: 1.4rem;
      }
    }
    .fee-content-wallet-balance {
      display: block;
      margin-top: 0.3rem;
      .balance-title {
        width: 7.16rem;
        display: flex;
        align-items: center;
        height: 0.45rem;
        margin: 0 auto;
        span {
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          color: #333333;
          line-height: 0.45rem;
        }
        :nth-child(1) {
          font-size: 0.24rem;
          flex: 1;
        }
        :nth-child(2) {
          width: 0.1rem;
          height: 0.1rem;
          background: #57d4aa;
          border-radius: 50%;
        }
        :nth-child(3) {
          font-size: 0.18rem;
          margin-left: 0.1rem;
        }
      }
      .fee-content-wallet-balance-wrap {
        position: relative;
        width: 8.33rem;
        height: 1.65rem;
        background: url('../../assets/pc/fee/banlance_projection.png') no-repeat;
        background-size: 100% 100%;
        .fee-banlance-bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.51rem;
          height: 1.67rem;
        }
        .fee-wallet-banlance-box {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 6.7rem;
          height: 1.03rem;
          border: 0.02rem solid white;
          border-radius: 0.1rem;
          display: flex;
          justify-content: space-evenly;
          .box-item {
            width: 2.22rem;
            height: 0.99rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .item-left {
              height: 0.99rem;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              flex: 1;
              .amount {
                margin-top: 0.2rem;
                width: 1.68rem;
                height: 0.3rem;
                font-size: 0.22rem;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                text-align: center;
                color: #333333;
                line-height: 0.3rem;
                &.active {
                  color: #2761e7;
                }
              }
              .item-type {
                width: 1.1rem;
                height: 0.24rem;
                background: #ffffff;
                border-radius: 0.12rem 0.12rem 0 0;
                display: flex;
                justify-content: center;
                align-items: center;
                &.active {
                  background: #2761e7;
                }
                img {
                  width: 0.16rem;
                  height: 0.16rem;
                  &.active {
                    width: 0.29rem;
                  }
                }
                span {
                  margin-left: 0.04rem;
                  height: 0.17rem;
                  font-size: 0.12rem;
                  font-family: PingFangSC, PingFangSC-Regular;
                  font-weight: 400;
                  text-align: center;
                  color: #333333;
                  line-height: 0.17rem;
                  &.active {
                    color: #ffffff;
                  }
                }
              }
            }
            .line {
              width: 0.01rem;
              height: 0.38rem;
              background: #ffffff;
            }
          }
        }
      }
    }
    .fee-content-discount-mini {
      display: none;
      margin-top: 0.3rem;
      position: relative;
      .fee-discount-wrap {
        width: 8.33rem;
        height: 3.34rem;
        background: url('../../assets/pc/fee/discount_projection.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        .fee-content-bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.52rem;
          height: 3.24rem;
        }
        .fee-level-wrap {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.52rem;
          height: 3.24rem;
          .fee-level-top {
            height: 0.58rem;
            display: flex;
            justify-content: space-evenly;
            margin-top: 0.1rem;
            span {
              width: 2rem;
              font-size: 0.2rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              text-align: center;
              color: #333333;
              line-height: 0.58rem;
            }
          }
          .fee-level-list {
            position: relative;
            width: 6.72rem;
            height: 0.58rem;
            margin: 0 auto;
            display: flex;
            justify-content: space-evenly;
            span {
              width: 2rem;
              font-size: 0.18rem;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #333333;
              line-height: 0.58rem;
            }
            .balance {
              margin-left: 0.7rem;
            }
            .discount-rate {
              margin-left: 1.6rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              text-align: left;
              color: #2761e7;
            }
            .rank {
              width: 2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 0.15rem;
              img {
                width: 0.44rem;
                height: 0.44rem;
                margin-right: 0.2rem;
              }
            }
          }
          .whiteBg {
            position: absolute;
            z-index: -10000;
            width: 6.72rem;
            height: 0.58rem;
            margin: 0 auto;
            opacity: 0.4;
            background: #ffffff;
          }
        }
      }
      .xtpt {
        position: absolute;
        top: -0.2rem;
        right: -0.15rem;
        width: 1.57rem;
        height: 1.49;
      }
      .tpt {
        position: absolute;
        bottom: 0;
        left: -0.36rem;
        width: 1.41rem;
        height: 1.4rem;
      }
    }
    .fee-content-wallet-balance-mini {
      display: none;
      margin-top: 0.3rem;
      .fee-content-wallet-balance-wrap {
        position: relative;
        width: 8.33rem;
        height: 1.65rem;
        background: url('../../assets/pc/fee/banlance_projection.png') no-repeat;
        background-size: 100% 100%;
        .fee-banlance-bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.51rem;
          height: 1.67rem;
        }
        .fee-wallet-banlance-box {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 6.7rem;
          height: 1.03rem;
          border: 0.02rem solid white;
          border-radius: 0.1rem;
          display: flex;
          justify-content: space-evenly;
          .box-item {
            width: 2.22rem;
            height: 0.99rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .item-left {
              height: 0.99rem;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              flex: 1;
              .amount {
                margin-top: 0.2rem;
                height: 0.3rem;
                font-size: 0.22rem;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                text-align: center;
                color: #333333;
                line-height: 0.3rem;
                &.active {
                  color: #2761e7;
                }
              }
              .item-type {
                width: 1.1rem;
                height: 0.24rem;
                background: #ffffff;
                border-radius: 0.12rem 0.12rem 0 0;
                display: flex;
                justify-content: center;
                align-items: center;
                &.active {
                  background: #2761e7;
                }
                img {
                  width: 0.16rem;
                  height: 0.16rem;
                  &.active {
                    width: 0.29rem;
                  }
                }
                span {
                  margin-left: 0.04rem;
                  height: 0.17rem;
                  font-size: 0.12rem;
                  font-family: PingFangSC, PingFangSC-Regular;
                  font-weight: 400;
                  text-align: center;
                  color: #333333;
                  line-height: 0.17rem;
                  &.active {
                    color: #ffffff;
                  }
                }
              }
            }
            .line {
              width: 0.01rem;
              height: 0.38rem;
              background: #ffffff;
            }
          }
        }
      }
    }
  }
}
// 移动端css
@media screen and (max-width: 767px) {
  .title {
    .common-title();
    margin-left: 0.2rem;
  }
  .fee-content {
    .fee-content-top {
      width: 6.68rem;
      margin: 0 auto;
      margin-top: 0.47rem;
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: justify;
      color: #333333;
    }
    .fee-content-discount {
      display: none;
      margin-top: 0.3rem;
      position: relative;
      .fee-discount-wrap {
        width: 8.33rem;
        height: 3.34rem;
        background: url('../../assets/pc/fee/discount_projection.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        .fee-content-bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.52rem;
          height: 3.24rem;
        }
        .fee-level-wrap {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.52rem;
          height: 3.24rem;
          .fee-level-top {
            width: 6.72rem;
            height: 0.58rem;
            display: flex;
            justify-content: space-evenly;
            margin: o auto;
            margin-top: 0.1rem;
            span {
              // width: 2rem;
              font-size: 0.2rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              text-align: center;
              color: #333333;
              line-height: 0.58rem;
            }
          }
          .fee-level-list {
            position: relative;
            width: 6.72rem;
            height: 0.58rem;
            margin: 0 auto;
            display: flex;
            justify-content: space-evenly;
            span {
              // width: 2rem;
              font-size: 0.18rem;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              // text-align: left;
              color: #333333;
              line-height: 0.58rem;
            }
            .balance {
              // margin-left: 0.7rem;
            }
            .discount-rate {
              // margin-left: 1.6rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              text-align: left;
              color: #2761e7;
            }
            .rank {
              width: 2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 0.15rem;
              img {
                width: 0.44rem;
                height: 0.44rem;
                margin-right: 0.2rem;
              }
            }
          }
          .whiteBg {
            position: absolute;
            z-index: -10000;
            width: 6.72rem;
            height: 0.58rem;
            margin: 0 auto;
            opacity: 0.4;
            background: #ffffff;
          }
        }
      }
      .xtpt {
        position: absolute;
        top: -0.2rem;
        right: -0.15rem;
        width: 1.57rem;
        height: 1.49;
      }
      .tpt {
        position: absolute;
        bottom: 0;
        left: -0.36rem;
        width: 1.41rem;
        height: 1.4rem;
      }
    }
    .fee-content-wallet-balance {
      display: none;
      margin-top: 0.3rem;
      .fee-content-wallet-balance-wrap {
        position: relative;
        width: 8.33rem;
        height: 1.65rem;
        background: url('../../assets/pc/fee/banlance_projection.png') no-repeat;
        background-size: 100% 100%;
        .fee-banlance-bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.51rem;
          height: 1.67rem;
        }
        .fee-wallet-banlance-box {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 6.7rem;
          height: 1.03rem;
          border: 0.02rem solid white;
          border-radius: 0.1rem;
          display: flex;
          justify-content: space-evenly;
          .box-item {
            width: 2.22rem;
            height: 0.99rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .item-left {
              height: 0.99rem;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              flex: 1;
              .amount {
                margin-top: 0.2rem;
                width: 1.68rem;
                height: 0.3rem;
                font-size: 0.22rem;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                text-align: center;
                color: #333333;
                line-height: 0.3rem;
                &.active {
                  color: #2761e7;
                }
              }
              .item-type {
                width: 1.1rem;
                height: 0.24rem;
                background: #ffffff;
                border-radius: 0.12rem 0.12rem 0 0;
                display: flex;
                justify-content: center;
                align-items: center;
                &.active {
                  background: #2761e7;
                }
                img {
                  width: 0.16rem;
                  height: 0.16rem;
                  &.active {
                    width: 0.29rem;
                  }
                }
                span {
                  margin-left: 0.04rem;
                  height: 0.17rem;
                  font-size: 0.12rem;
                  font-family: PingFangSC, PingFangSC-Regular;
                  font-weight: 400;
                  text-align: center;
                  color: #333333;
                  line-height: 0.17rem;
                  &.active {
                    color: #ffffff;
                  }
                }
              }
            }
            .line {
              width: 0.01rem;
              height: 0.38rem;
              background: #ffffff;
            }
          }
        }
      }
    }
    .fee-content-discount-mini {
      display: block;
      margin-top: 0.3rem;
      position: relative;
      .fee-discount-wrap {
        width: 7.5rem;
        height: 4.09rem;
        margin: 0 auto;
        background: url('../../assets/mobile/fee/discount_bg.png') no-repeat;
        background-size: 100% 100%;
        position: relative;

        .fee-level-wrap {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 7.52rem;
          height: 3.24rem;
          .fee-level-top {
            width: 6.72rem;
            height: 0.58rem;
            display: flex;
            justify-content: space-evenly;
            margin: 0 auto;
            margin-top: 0.1rem;
            span {
              font-size: 0.2rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              text-align: center;
              color: #333333;
              line-height: 0.58rem;
            }
            span:nth-child(1) {
              width: 1.5rem;
              // text-align: right;
            }
            span:nth-child(2) {
              // width: 3rem;
              flex: 1;
            }
            span:nth-child(3) {
              width: 2rem;
            }
          }
          .fee-level-list {
            position: relative;
            width: 6.72rem;
            height: 0.58rem;
            margin: 0 auto;
            display: flex;
            justify-content: space-evenly;
            span {
              font-size: 0.18rem;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: center;
              color: #333333;
              line-height: 0.58rem;
            }
            .balance {
              flex: 1;
            }
            .discount-rate {
              width: 2rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              color: #2761e7;
            }
            .rank {
              width: 1.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 0.44rem;
                height: 0.44rem;
                margin-right: 0.2rem;
              }
            }
          }
          .whiteBg {
            position: absolute;
            z-index: -10000;
            width: 6.72rem;
            height: 0.58rem;
            margin: 0 auto;
            opacity: 0.4;
            background: #ffffff;
          }
        }
      }
      .xtpt {
        position: absolute;
        top: -0.2rem;
        right: -0.15rem;
        width: 1.57rem;
        height: 1.49;
      }
      .tpt {
        position: absolute;
        bottom: 0;
        left: -0.36rem;
        width: 1.41rem;
        height: 1.4rem;
      }
    }
    .fee-content-wallet-balance-mini {
      display: block;
      margin-top: 0.3rem;
      .balance-title {
        display: flex;
        align-items: center;
        height: 0.45rem;
        padding: 0 0.2rem;
        span {
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          color: #333333;
          line-height: 0.45rem;
        }
        :nth-child(1) {
          font-size: 0.32rem;
          flex: 1;
        }
        :nth-child(2) {
          width: 0.1rem;
          height: 0.1rem;
          background: #57d4aa;
          border-radius: 50%;
        }
        :nth-child(3) {
          font-size: 0.24rem;
          margin-left: 0.1rem;
        }
      }
      .fee-content-wallet-balance-wrap {
        width: 7.5rem;
        // width: 100vw;
        height: 4rem;
        margin: 0 auto;

        .fee-wallet-banlance-box {
          background: url('../../assets/mobile/fee/banlance.png') no-repeat;
          background-size: 100% 100%;
          width: 7.5rem;
          height: 2rem;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          .box-item {
            width: 3.32rem;
            height: 0.99rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .item-left {
              height: 0.99rem;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              flex: 1;
              .amount {
                margin-top: 0.2rem;
                height: 0.3rem;
                font-size: 0.22rem;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                text-align: center;
                color: #333333;
                line-height: 0.3rem;
              }
              .item-type {
                width: 1.68rem;
                height: 0.41rem;
                background: #ffffff;
                border-radius: 0.12rem 0.12rem 0 0;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 0.1rem;
                img {
                  width: 0.16rem;
                  height: 0.16rem;
                }
                span {
                  margin-left: 0.04rem;
                  height: 0.17rem;
                  font-size: 0.12rem;
                  font-family: PingFangSC, PingFangSC-Regular;
                  font-weight: 400;
                  text-align: center;
                  color: #333333;
                  line-height: 0.17rem;
                }
              }
            }
            .line {
              width: 0.01rem;
              height: 0.38rem;
              background: #ffffff;
            }
          }
        }
        .fee-cenoent-banlance-total {
          top: 2rem;
          width: 7.5rem;
          height: 2rem;
          background: url('../../assets/mobile/fee/banlance_blue.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          .total-btn {
            width: 1.86rem;
            height: 0.7rem;
            margin-left: 0.5rem;
            background: #ffffff;
            border-radius: 0.12rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 0.57rem;
              height: 0.32rem;
            }
            span {
              margin-left: 0.1rem;
              font-size: 0.2rem;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: center;
              color: #333333;
            }
          }
          .amount {
            margin-left: 0.3rem;
            font-size: 0.32rem;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #2761e7;
          }
        }
      }
    }
  }
}
</style>
