<template>
  <div class="terms">
    <Header />
    <div class="terms-content">
      <div class="title">{{ $t('privacy_policy.title') }}</div>
      <div class="text date">{{ $t('privacy_policy.text_1_1') }}</div>
      <div class="text text_1" v-html="$t('privacy_policy.text_1_2')"></div>
      <div class="sub-title">{{ $t('privacy_policy.text_2_1') }}</div>
      <div class="text text_1">{{ $t('privacy_policy.text_2_2') }}</div>
      <div class="text">{{ $t('privacy_policy.text_2_3') }}</div>
      <div class="text">{{ $t('privacy_policy.text_2_4') }}</div>
      <div class="text">{{ $t('privacy_policy.text_2_5') }}</div>
      <div class="text">{{ $t('privacy_policy.text_2_6') }}</div>
      <div class="text">{{ $t('privacy_policy.text_2_7') }}</div>
      <div class="sub-title">{{ $t('privacy_policy.text_3_1') }}</div>
      <div class="text text_1">{{ $t('privacy_policy.text_3_2') }}</div>
      <div class="text">{{ $t('privacy_policy.text_3_3') }}</div>
      <div class="text">{{ $t('privacy_policy.text_3_4') }}</div>
      <div class="text">{{ $t('privacy_policy.text_3_5') }}</div>
      <div class="text">{{ $t('privacy_policy.text_3_6') }}</div>
      <div class="text">{{ $t('privacy_policy.text_3_7') }}</div>
      <div class="text">{{ $t('privacy_policy.text_3_8') }}</div>
      <div class="sub-title">{{ $t('privacy_policy.text_4_1') }}</div>
      <div class="text text_1">{{ $t('privacy_policy.text_4_2') }}</div>
      <div class="sub-title">{{ $t('privacy_policy.text_5_1') }}</div>
      <div class="text text_1">{{ $t('privacy_policy.text_5_2') }}</div>
      <div class="sub-title">{{ $t('privacy_policy.text_6_1') }}</div>
      <div class="text text_1">{{ $t('privacy_policy.text_6_2') }}</div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Layouts/Header.vue';
import Footer from '../components/Layouts/Footer.vue';
export default {
  components: { Header, Footer },
};
</script>

<style lang="less" scoped>
@import url('../style/terms.less');
</style>
