<template>
  <div class="donation-introduction-container">
    <div class="banner-container pic-full"></div>
    <div class="know-container">
      <p class="title">{{ $t('donation.know.title') }}</p>
      <p class="desc">{{ $t('donation.know.desc') }}</p>
    </div>
<!--    <div class="tip-container">-->
<!--      <div class="important-container flex-center">-->
<!--        <div class="important"></div>-->
<!--      </div>-->
<!--      <p class="title">{{ homeLang.donation.tip.title }}</p>-->
<!--      <div class="clear"></div>-->
<!--      <p class="desc">{{ homeLang.donation.tip.desc }}</p>-->
<!--    </div>-->
    <div class="donate-type-container">
      <p class="title">{{ $t('donation.donateType.title') }}</p>
      <p class="desc">{{ $t('donation.donateType.desc') }}</p>
    </div>
    <div class="donate-address-container">
      <p class="title">{{ $t('donation.address.title') }}</p>
      <div v-for="item in addressList" class="donate-address">
        <span class="desc" :class="{'lightgrey': item.copied}">{{ item.chain }}: {{ item.address }}</span>
        <img class="copy"
             src="../../assets/pc/copy.png"
             @click="copyAddress(item)"
             v-clipboard:copy="item.address"
             v-clipboard:success="clipboardSuccess">
      </div>
    </div>
  </div>
</template>

<script>
import clipboard from '../../directive/clipboard/index.js'
export default {
  name: "donationIntroduction",
  data() {
    return {
      addressList: [{
        chain: 'ETH',
        address: '0x1F0D22035fB7Fe47d0d852c243C43C626B58B436',
        copied: false
      },{
        chain: 'BSC',
        address: '0x1F0D22035fB7Fe47d0d852c243C43C626B58B436',
        copied: false
      },{
        chain: 'HECO',
        address: '0x1F0D22035fB7Fe47d0d852c243C43C626B58B436',
        copied: false
      },{
        chain: 'OEC',
        address: '0x1F0D22035fB7Fe47d0d852c243C43C626B58B436',
        copied: false
      },
      // {
      //   chain: 'HSC',
      //   address: '0x5f80670522f999e56310E0f3f8BBb5b0348B1EC8',
      //   copied: false
      // },
      {
        chain: 'TRON',
        address: 'TRPSDPVWkGahnA5iDR5vkjNifWhbaHmaH4',
        copied: false
      },{
        chain: 'EOS',
        address: 'tptdonation1',
        copied: false
      },]
    }
  },
  directives: {
    clipboard
  },
  methods: {
    clipboardSuccess() {
      const width = document.documentElement.clientWidth
      const isPC = width >= 767
      this.$toast({
        text: this.$t('donation.copied'),
        isPC: isPC
      })
    },
    copyAddress(item) {
      for (let addressItem of this.addressList) {
        addressItem.copied = false
      }
      item.copied = true
    }
  }
}
</script>

<style lang="less">
@media screen and (min-width: 767px) {
  .donation-introduction-container {
    width: 5.16rem;
    .banner-container {
      z-index: -99;
      margin-top: -0.6rem;
      width: 4.9rem;
      height: 3.47rem;
      background-image: url("../../assets/pc/donation/banner.png");
    }
    .know-container {
      margin-top: 0.11rem;
    }
    .tip-container, .donate-type-container, .donate-address-container {
      margin-top: 0.31rem;
    }
    .title {
      font-size: 0.18rem;
      font-family: PingFangSC, PingFangSC-Medium;
      color: #333333;
      line-height: 0.25rem;
      margin-left: 0.13rem;
      width: 5.03rem;
      font-weight: bold;
    }
    .desc {
      font-size: 0.16rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #666666;
      line-height: 0.22rem;
      margin-left: 0.13rem;
      margin-top: 0.1rem;
      width: 5.03rem;
    }
    .tip-container {
      .important-container {
        float: left;
        width: 0.13rem;
        height: 0.25rem;
        .important {
          width: 0.06rem;
          height: 0.06rem;
          border-radius: 0.03rem;
          background-color: #ED4C56;
        }
      }
      .title {
        float: left;
        width: 4.9rem;
        margin-left: 0;
      }
    }
    .donate-address-container {
      .donate-address {
        height: 0.25rem;
        display: flex;
        align-items: center;
        &:first-of-type {
          margin-top: 0.1rem;
        }
        span {
          font-size: 0.14rem;
          width: auto;
          margin-top: 0;

          &.lightgrey {
            color: #c5c6ca;
          }
        }
        .copy {
          width: 0.12rem;
          height: 0.12rem;
          margin-left: 0.12rem;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .donation-introduction-container {
    width: 7.1rem;
    margin: 0 auto;
    .banner-container {
      z-index: -99;
      margin-top: -0.78rem;
      width: 6.56rem;
      height: 4.64rem;
      background-image: url("../../assets/pc/donation/banner.png");
    }
    .know-container {
      margin-top: 0.15rem;
    }
    .tip-container, .donate-type-container, .donate-address-container {
      margin-top: 0.45rem;
    }
    .title {
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Medium;
      color: #333333;
      line-height: 0.4rem;
      margin-left: 0.13rem;
      font-weight: bold;
    }
    .desc {
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #666666;
      line-height: 0.35rem;
      margin-left: 0.13rem;
      margin-top: 0.15rem;
    }
    .tip-container {
      .important-container {
        float: left;
        width: 0.2rem;
        height: 0.4rem;
        .important {
          width: 0.1rem;
          height: 0.1rem;
          border-radius: 0.05rem;
          background-color: #ED4C56;
        }
      }
      .title {
        float: left;
        width: 6.9rem;
        margin-left: 0;
      }
    }
    .donate-address-container {
      .donate-address {
        display: flex;
        align-items: center;
        width: 200%;
        transform: scale(0.5);
        transform-origin: 0;
        padding-left: 0.13rem;

        &:first-of-type {
          margin-top: 0.1rem;
        }
        .desc {
          font-size: 0.4rem;
          line-height: 0.24rem;

          &.lightgrey {
            color: #c5c6ca;
          }
        }
        .copy {
          height: 0.4rem;
          width: 0.4rem;
          margin-left: 0.2rem;
          transform-origin: 0 center;
        }
      }
    }
  }
}
</style>
