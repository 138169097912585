<template>
  <div class="stake-introduction-container">
    <div class="banner-container pic-full"></div>
    <span class="float-coin coin"></span>
    <span class="float-coin coin1"></span>
    <span class="float-coin coin2"></span>
    <span class="float-coin coin3"></span>
    <div class="stake-know-container pic-full">
      <p class="title">{{ $t('stake.know.title') }}</p>
      <p class="desc">{{ $t('stake.know.desc') }}</p>
    </div>
    <!-- <div class="tip-container">
      <div class="important-container flex-center">
        <div class="important"></div>
      </div>
      <p class="title">{{ homeLang.stake.tip.title }}</p>
      <div class="clear"></div>
      <p class="desc">{{ homeLang.stake.tip.desc }}</p>
    </div> -->
    <!-- <div class="donate-type-container">
      <p class="title">{{ homeLang.stake.donateType.title }}</p>
      <p class="desc">{{ homeLang.stake.donateType.desc }}</p>
    </div>
    <div class="donate-address-container">
      <p class="title">{{ homeLang.stake.address.title }}</p>
      <div v-for="item in addressList" class="donate-address">
        <span class="desc" :class="{ lightgrey: item.copied }"
          >{{ item.chain }}: {{ item.address }}</span
        >
        <img
          class="copy"
          src="../../assets/pc/copy.png"
          @click="copyAddress(item)"
          v-clipboard:copy="item.address"
          v-clipboard:success="clipboardSuccess"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import clipboard from "../../directive/clipboard/index.js";
export default {
  name: "stakeIntroduction",
  // props: {
  //   homeLang: {
  //     type: Object,
  //   },
  // },
  created() {
    // console.log(this.homeLang);
  },
  data() {
    return {
      addressList: [
        {
          chain: "ETH",
          address: "0x5f80670522f999e56310E0f3f8BBb5b0348B1EC8",
          copied: false,
        },
        {
          chain: "BSC",
          address: "0x5f80670522f999e56310E0f3f8BBb5b0348B1EC8",
          copied: false,
        },
        {
          chain: "HECO",
          address: "0x5f80670522f999e56310E0f3f8BBb5b0348B1EC8",
          copied: false,
        },
        {
          chain: "OEC",
          address: "0x5f80670522f999e56310E0f3f8BBb5b0348B1EC8",
          copied: false,
        },
        {
          chain: "HSC",
          address: "0x5f80670522f999e56310E0f3f8BBb5b0348B1EC8",
          copied: false,
        },
        {
          chain: "TRON",
          address: "TMPKq4P49oKRqn4Apec7asSnz682Dj4Bip",
          copied: false,
        },
        {
          chain: "EOS",
          address: "tptstake1",
          copied: false,
        },
      ],
    };
  },
  directives: {
    clipboard,
  },
  methods: {
    clipboardSuccess() {
      const width = document.documentElement.clientWidth;
      const isPC = width >= 767;
      this.$toast({
        text: this.$t('stake.copied'),
        isPC: isPC,
      });
    },
    copyAddress(item) {
      for (let addressItem of this.addressList) {
        addressItem.copied = false;
      }
      item.copied = true;
    },
  },
};
</script>

<style lang="less">
.stake-introduction-container {
  position: relative;
}
.stake-know-container {
  position: relative;
  background-image: url("../../assets/pc/stake/title_bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 32px;
  box-sizing: border-box;
  margin-top: -200px;
  min-height: 158px;
}

.float-coin {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.coin {
  width: 2rem;
  height: 2rem;
  top: 0.6rem;
  left: 1.5rem;
  background-image: url("../../assets/pc/stake/coin.png");
}

.coin1 {
  width: 0.46rem;
  height: 0.41rem;
  top: 1.5rem;
  left: 1rem;
  background-image: url("../../assets/pc/stake/coin1.png");
}

.coin2 {
  width: 0.49rem;
  height: 0.44rem;
  top: 0.7rem;
  right: 1.3rem;
  background-image: url("../../assets/pc/stake/coin2.png");
}

.coin3 {
  width: 0.48rem;
  height: 0.36rem;
  top: 1.8rem;
  right: 1.3rem;
  background-image: url("../../assets/pc/stake/coin3.png");
}

@media screen and (min-width: 767px) {
  .stake-introduction-container {
    width: 5.16rem;
    .banner-container {
      z-index: -99;
      // margin-top: -0.6rem;
      width: 4.9rem;
      height: 6.47rem;
      background-image: url("../../assets/pc/stake/banner.png");
      background-size: 85%;
      background-position: 50% 50%;
    }

    .know-container {
      margin-top: 0.11rem;
    }
    .tip-container,
    .donate-type-container,
    .donate-address-container {
      margin-top: 0.31rem;
    }
    .title {
      font-size: 0.22rem;
      font-family: PingFangSC, PingFangSC-Medium;
      color: #fff;
      line-height: 0.25rem;
      margin: 0 0 8px 0;

      // width: 5.03rem;
      // font-weight: bold;
    }
    .desc {
      font-size: 0.14rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #fff;
      line-height: 0.22rem;
      // margin-top: 0.1rem;
      // width: 5.03rem;
    }
    .tip-container {
      .important-container {
        float: left;
        width: 0.13rem;
        height: 0.25rem;
        .important {
          width: 0.06rem;
          height: 0.06rem;
          border-radius: 0.03rem;
          background-color: #ed4c56;
        }
      }
      .title {
        float: left;
        width: 4.9rem;
        margin-left: 0;
      }
    }
    .donate-address-container {
      .donate-address {
        height: 0.25rem;
        display: flex;
        align-items: center;
        &:first-of-type {
          margin-top: 0.1rem;
        }
        span {
          font-size: 0.14rem;
          width: auto;
          margin-top: 0;

          &.lightgrey {
            color: #c5c6ca;
          }
        }
        .copy {
          width: 0.12rem;
          height: 0.12rem;
          margin-left: 0.12rem;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .float-coin {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .coin {
    width: 2rem;
    height: 2rem;
    top: 0.3rem;
    left: 2.8rem;
    background-image: url("../../assets/pc/stake/coin.png");
  }

  .coin1 {
    width: 0.46rem;
    height: 0.41rem;
    top: 1.5rem;
    left: 1.8rem;
    background-image: url("../../assets/pc/stake/coin1.png");
  }

  .coin2 {
    width: 0.49rem;
    height: 0.44rem;
    top: 0.3rem;
    right: 2.3rem;
    background-image: url("../../assets/pc/stake/coin2.png");
  }

  .coin3 {
    width: 0.48rem;
    height: 0.36rem;
    top: 1.5rem;
    right: 2.3rem;
    background-image: url("../../assets/pc/stake/coin3.png");
  }

  .stake-introduction-container {
    .banner-container {
      z-index: -99;
      margin: 0.35rem auto 0;
      width: 5.51rem;
      height: 7.26rem;
      background-image: url("../../assets/pc/stake/banner.png");
    }

    .stake-know-container {
      background-image: url("../../assets/pc/stake/title_bg.png");
      margin-top: -1.81rem;
      padding: 0.5rem;
      width: 7.49rem;
      height: 2.37rem;
    }

    .know-container {
      margin-top: 0.15rem;
    }
    .tip-container,
    .donate-type-container,
    .donate-address-container {
      margin-top: 0.45rem;
    }
    .title {
      font-size: 0.3rem;
      font-family: PingFangSC, PingFangSC-Medium;
      color: #fff;
      line-height: 0.3rem;
      margin-left: 0rem;
      font-weight: bold;
    }
    .desc {
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #fff;
      line-height: 0.35rem;
      margin-left: 0rem;
      margin-top: 0.15rem;
    }
    .tip-container {
      .important-container {
        float: left;
        width: 0.2rem;
        height: 0.4rem;
        .important {
          width: 0.1rem;
          height: 0.1rem;
          border-radius: 0.05rem;
          background-color: #ed4c56;
        }
      }
      .title {
        float: left;
        width: 6.9rem;
        margin-left: 0;
      }
    }
    .donate-address-container {
      .donate-address {
        display: flex;
        align-items: center;
        width: 200%;
        transform: scale(0.5);
        transform-origin: 0;
        padding-left: 0.13rem;

        &:first-of-type {
          margin-top: 0.1rem;
        }
        .desc {
          font-size: 0.4rem;
          line-height: 0.24rem;

          &.lightgrey {
            color: #c5c6ca;
          }
        }
        .copy {
          height: 0.4rem;
          width: 0.4rem;
          margin-left: 0.2rem;
          transform-origin: 0 center;
        }
      }
    }
  }
}
</style>
